import DataGrid from 'react-data-grid';

const columns = [
    { key: 'argumentOrder', name: '#', },
    { key: 'name', name: 'Name', },
    { key: 'type', name: 'Type', },
    { key: 'value', name: 'Data', },
];

const ResultsViewer = ({ decodedInput }) => {
    const { functionArguments, functionName } = decodedInput;
    const rows = functionArguments.map(([type, name, value], argumentOrder) => {
        return { argumentOrder, name, type, value };
    });
    return (
        <div>
            <h4>function Name: <b>{functionName}</b></h4>
            <DataGrid columns={columns} rows={rows} style={{ blockSize: '100%' }} />
        </div>
    )
}

ResultsViewer.propTypes = {
}

export default ResultsViewer
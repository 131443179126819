import './App.css';
import DecoderForm from './Components/DecoderForm/DecoderForm'
import 'bootstrap/dist/css/bootstrap.min.css'
import ResultsViewer from './Components/JsonViewer/ResultsViewer';
import { Component } from 'react';
import Container from 'react-bootstrap/Container';
import styles from './Components/Forms.module.css'
import Stack from 'react-bootstrap/Stack';
import Alert from 'react-bootstrap/Alert';
import packageJson from '../package.json';

class App extends Component {

  state = {
    decodedInput: null,
    errorMessage: null,
  };

  handleDecodedInput = (decodedInput) => this.setState({ decodedInput, errorMessage: null });
  handleErrorMessage = (errorMessage) => this.setState({ errorMessage, decodedInput: null });
  clearErrorMessage = () => this.handleErrorMessage(null);
  onFormChange = (e) => { this.clearErrorMessage() };

  render() {
    return (
      <div className="App">
        <Container className={`${styles.container}  ${styles.form}`}>
          <Stack gap={5}>
            <DecoderForm handleDecodedInput={this.handleDecodedInput} handleErrorMessage={this.handleErrorMessage} onFormChange={this.onFormChange} />
            {
              this.state.decodedInput !== null &&
              <ResultsViewer decodedInput={this.state.decodedInput} />
            }
            {
              this.state.errorMessage !== null &&
              <Alert variant="danger" onClose={() => this.clearErrorMessage()} dismissible>
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>{this.state.errorMessage}</p>
              </Alert>
            }
          </Stack>
          <div className="bottom-divider"></div>
          <div style={{ textAlign: "start" }}>
            <h4>What is this?</h4>
            <p>When you need to quickly decode a given input for a verified SC address on <a href="https://etherscan.io/">etherscan</a>.
              This service helps you just to do that without inputting the abi file.</p>
            <h4>How it works?</h4>
            <p>You enter the address and the input data, and finally hit the "Decode" button. The front will fetch the abi from etherscan,
              the backend uses <a href="https://github.com/kigawas/web3-input-decoder">web3-input-decoder</a> to decode the provided input.</p>
            <h4>Similar tools</h4>
            <ul className="a">
              <li><a href="https://lab.miguelmota.com/ethereum-input-data-decoder/example/">ethereum-input-data-decoder</a>.</li>
              <li><a href="https://abi.hashex.org/">Online ABI Encoding Service</a>.</li>
            </ul>
            <div className="bottom-divider"></div>
            <h5>Acknowledgements</h5>
            <ul className="a">
              <li><a href="https://www.flaticon.com/free-icons/smart-contracts">icon used</a> created by Assia Benkerroum - Flaticon.</li>
              <li><a href="https://github.com/ChainSafe/web3.js">Web3</a> for input validation.</li>
              <li><a href="https://github.com/jquense/yup">Yup</a> for schema validation.</li>
              <li><a href="https://github.com/jaredpalmer/formik">Formik</a> for building the form.</li>
            </ul>
            <div className="bottom-divider"></div>
          </div>
          <p style={{ fontSize: "11px", float: "left" }}>Made by <a href="https://fadi.bakoura.com/">Fadi Bakoura</a>.</p>
          <p style={{ fontSize: "11px", float: "right" }}>Version: {packageJson.version}</p>
        </Container >
      </div >
    );
  }
}

export default App;

import { useState } from 'react';
import styles from '../Forms.module.css'
import FormInput from '../FormInput/FormInput';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { Container, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Web3 from 'web3/dist/web3.min.js'

Yup.addMethod(Yup.string, "SmartContractAddress", function (errorMessage) {
    return this.test(`test-smart-contract-address`, errorMessage, function (value) {
        const { path, createError } = this;
        return (
            Web3.utils.isAddress(value) ||
            createError({ path, message: errorMessage })
        );
    });
});

Yup.addMethod(Yup.string, "HexStrict", function (errorMessage) {
    return this.test(`test-hex-strict`, errorMessage, function (value) {
        const { path, createError } = this;
        return (
            Web3.utils.isHexStrict(value) ||
            createError({ path, message: errorMessage })
        );
    });
});

const DecoderForm = ({ handleDecodedInput, handleErrorMessage, onFormChange: _onFormChange }) => {
    const [{ submit, loading }, setState] = useState({ submit: false, loading: false })

    const formik = useFormik({
        initialValues: {
            smartContractAddress: '',
            inputData: '',
        },
        validationSchema: Yup.object({
            smartContractAddress: Yup.string().SmartContractAddress("not valid smart contract address").required('please enter a smart contract address'),
            inputData: Yup.string().HexStrict("not Hex strict").required('Please enter the contract input data'),
        }),
        onSubmit: async (values) => {
            setState({ loading: true });

            const abiFileResponse = await axios({
                method: 'get',
                url: `https://api.etherscan.io/api?module=contract&action=getabi&address=${values.smartContractAddress}`,
            });

            if (abiFileResponse.data.status !== '1') {
                handleErrorMessage(abiFileResponse.data.result);
                setState({ loading: false });
                return;
            }

            axios({
                method: 'post',
                url: 'https://wgsfu7btrc.execute-api.eu-west-3.amazonaws.com/dev/decode_smart_contract_data',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify({
                    "abi_file": abiFileResponse.data.result,
                    "raw_contract_data": values.inputData
                }),
            }).then((response) => {
                handleDecodedInput({
                    functionName: response.data["function_name"],
                    functionArguments: response.data["decoded_input"]
                });
            }).catch((error) => {
                handleErrorMessage(`Failed to decode: ${error.response.data}`);
            }).finally(() => {
                setState({ loading: false });
            });
        }
    })

    const isFormEmpty = () => {
        const { inputData, smartContractAddress } = formik.values;
        return inputData.length === 0 || smartContractAddress.length === 0;
    }

    const onFormChange = (args) => {
        setState({ submit: false });
        return _onFormChange(args);
    }

    return (
        <Container fluid className={`d-flex justify-content-center align-items-center px-5`}>
            <Form noValidate onSubmit={formik.handleSubmit} onInput={onFormChange}>
                <h2>Fast decode your smart contract input</h2>
                <FormInput
                    className="mt-5 mb-4"
                    controlId="smartContractAddressID"
                    name="smartContractAddress"
                    text="Enter a smart contract address"
                    placeholder="0x60c0f8411bdfeb43746f51d088f0af30f6fd5f23"
                    invalid={submit && formik.errors.smartContractAddress ? true : false}
                    errMsg={formik.errors.smartContractAddress || ''}
                    valid={submit && !formik.errors.smartContractAddress ? true : false}
                    {...formik.getFieldProps('smartContractAddress')}
                />
                <FormInput
                    className="mb-4"
                    controlId="inputDataID"
                    name="inputData"
                    text="contract's input data"
                    placeholder="0xa0712d680000000000000000000000000000000000000000000000000000000000000003"
                    invalid={submit && formik.errors.inputData ? true : false}
                    errMsg={formik.errors.inputData || ''}
                    valid={submit && !formik.errors.inputData ? true : false}
                    as="textarea"
                    rows={3}
                    {...formik.getFieldProps('inputData')}
                />
                <Button
                    className={`${styles["submit-btn"]} w-100`}
                    onClick={() => setState({ submit: true })}
                    disabled={((isFormEmpty() || loading) || (submit && !formik.isValid)) ? true : false}
                    variant="primary"
                    type="submit">
                    <span style={{ paddingRight: '0.1em' }}> Decode </span>
                    {(
                        loading &&
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </Button>
            </Form>
        </Container>
    )
}

// validate component
DecoderForm.propTypes = {
}

export default DecoderForm